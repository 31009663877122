import { useEffect } from 'react'
import type { NextPageContext } from 'next'
import { inject, observer } from 'mobx-react'

import { appHomeInit, getHomeData } from '../../utils/nextUtils'
import { CACHE_POLICY } from '../../utils/constants'
import { Home as HomePageRoot } from 'pages/Home'
import { safeStringify } from 'utils/utils'

import UnrecoverableServerError from '../../components/errors/unrecoverableServerError'

const HomePage = inject(
	'Home',
	'Infra'
)(
	observer(({ Home, Infra, data, error }: any) => {
		// Hydrate the Home mobx store with logo, branches list and featured items
		if (data) {
			Home.setLogo(data.homeLogo)
			Home.filterAndSetFeaturedItems(data.homeFeaturedItems)
		}

		// Stop loading when the page is client side rendered
		Infra.setLoading(false)

		useEffect(() => {
			// Fetch branches list on client side, cause chain/stores can be opened/closed during the cache session
			const getBranchesList = async () => {
				const result = await getHomeData(Infra.appParams, null, 'getBranchesList')
				Home.setBranchesList(result)
			}
			getBranchesList()
		}, [])

		if (!data) {
			return <UnrecoverableServerError message={error} />
		}

		return <HomePageRoot />
	})
)

export async function getServerSideProps(
	context: NextPageContext & { req: { extra: { appParams: any; nextJsError: string; forwardedUrl: string }; cookies: Record<string, string> } }
) {
	if (context.res) {
		context.res.setHeader(
			'Cache-Control',
			`public, s-maxage=${CACHE_POLICY.DEFAULT.MAX_AGE}, stale-while-revalidate=${CACHE_POLICY.DEFAULT.REVALIDATED_AGE}`
		)
	}

	// Home page data fetching
	if (context.req && !context.req.extra?.nextJsError) {
		try {
			const { homeLogo, homeFeaturedItems } = await appHomeInit({
				appParams: context.req.extra?.appParams,
				forwardedUrl: context.req.extra.forwardedUrl,
				cookies: context.req.cookies,
			})

			const data: any = {
				homeLogo,
				homeFeaturedItems,
			}

			return {
				props: {
					data,
				},
			}
		} catch (e: any) {
			return { props: { data: null, error: `Fail to fetch home page data. ${safeStringify(e)}` } }
		}
	}

	return { props: { data: null } }
}

export default HomePage
