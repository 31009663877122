// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import dynamic from 'next/dynamic'

import { getTranslatedTextByKey, isMenuAutoLoad } from 'utils/utils'
import Skeleton from '../components/Skeleton'
import { Page } from 'utils/constants'

const HomeContent = dynamic(() => import('../static/components/home/HomeContent').then((mod) => mod.HomeContent))

export const Home = inject(
	'Infra',
	'Home',
	'Payment',
	'AddressManager'
)(
	observer((props) => {
		const { Infra, Home: home, location, AddressManager } = props
		const [showCart, setShowCart] = useState(false)

		useEffect(() => {
			setShowCart(AddressManager.isUserLocalized())
		}, [])

		return (
			<Skeleton
				page={Page.HOME}
				title={getTranslatedTextByKey('webHeaderTitle') || ''}
				showCart={showCart}
				showSearch={false}
				showCustomMessage={false}
				showLogo
				showMenuTabs={false}
				showPaymentMessage={false}
				logoURL={home?.logo?.msg}
				isMenuAutoLoad={isMenuAutoLoad(Infra.appParams)}
				showStoreInfo
			>
				<HomeContent location={location} />
			</Skeleton>
		)
	})
)

export default withRouter(Home)
